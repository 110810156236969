import React, { FC } from "react";
import CategoryBadgeList from "components/CategoryBadgeList/CategoryBadgeList";
import Link from "components/Link";
import PostCardLikeAndComment from "components/PostCardLikeAndComment/PostCardLikeAndComment";
import PostCardPrayerAction from "./PostCardPrayerAction";
import { PrayerRequest } from "types/types";

export interface PrayerFeedCardProps {
  className?: string;
  post: PrayerRequest;
}

const PrayerFeedCard: FC<PrayerFeedCardProps> = ({ className = "", post }) => {
  const {  title, details, location, createdDate } = post;
  return (
    <div
      className={`nc-Card5 relative p-5 group border border-neutral-200 hover:shadow-lg transition-shadow dark:border-neutral-700 rounded-3xl bg-white dark:bg-neutral-900 ${className}`}
    >

      <div className="flex flex-col">
        <h2
          className="block text-base font-semibold text-neutral-800 dark:text-neutral-300 my-4"
          title={title}
        >
           {title}
        </h2>
        <p className="text-sm text-neutral-500 dark:text-neutral-400">
          {details}
        </p>
      </div>
      <div className="flex items-end justify-between mt-auto">
        <PostCardLikeAndComment
          className="relative"
          blogId=""
          likeCount={0}
          viewCount={0}
        />
        <PostCardPrayerAction className="relative" />
      </div>
    </div>
  );
};

export default PrayerFeedCard;
