import React, { FC, useRef, useState } from "react";
import Avatar from "components/Avatar/Avatar";
import NcDropDown, { NcDropDownItem } from "components/NcDropDown/NcDropDown";
import twFocusClass from "utils/twFocusClass";
import ModalReportItem from "components/ModalReportItem/ModalReportItem";
import Link from "components/Link";
import SingleCommentForm from "app/(singles)/SingleCommentForm";
import ModalEditComment from "components/CommentCard/ModalEditComment";
import CommentCardLikeReply from "components/CommentCardLikeReply/CommentCardLikeReply";
import ModalDeleteComment from "components/CommentCard/ModalDeleteComment";
import { CommentType } from "data/types";

export interface CommentCardBlogProps {
  className?: string;
  comment: CommentType;
  size?: "large" | "normal";
}

const CommentCardBlog: FC<CommentCardBlogProps> = ({
  className = "",
  comment,
  size = "large",
}) => {
  const { date, content, like } = comment;

  // Dropdown Actions
  const actions: NcDropDownItem[] = [
    { id: "edit", name: "Edit", icon: getSvgIcon("edit") },
    { id: "reply", name: "Reply", icon: getSvgIcon("reply") },
    { id: "report", name: "Report abuse", icon: getSvgIcon("report") },
    { id: "delete", name: "Delete", icon: getSvgIcon("delete") },
  ];

  // States for modals and reply form
  const [isReplying, setIsReplying] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isReporting, setIsReporting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  // Ref for the reply textarea
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  // Dropdown Action Handlers
  const handleActionClick = (action: NcDropDownItem) => {
    switch (action.id) {
      case "reply":
        setIsReplying(true);
        textareaRef.current?.focus();
        break;
      case "edit":
        setIsEditing(true);
        break;
      case "report":
        setIsReporting(true);
        break;
      case "delete":
        setIsDeleting(true);
        break;
    }
  };

  // Render Reply Form
  const renderReplyForm = () => (
    <SingleCommentForm
      onClickSubmit={() => setIsReplying(false)}
      onClickCancel={() => setIsReplying(false)}
      className="flex-grow"
    />
  );

  return (
    <>
      <div className={`nc-CommentCard flex ${className}`}>
        {/* Avatar */}
        <Avatar
          imgUrl={comment.photoURL}
          sizeClass={`h-6 w-6 text-base ${
            size === "large" ? "sm:text-lg sm:h-8 sm:w-8" : ""
          }`}
          radius="rounded-full"
          containerClassName="mt-4"
        />
        <div className="flex-grow flex flex-col p-4 ml-2 text-sm border border-neutral-200 rounded-xl sm:ml-3 sm:text-base dark:border-neutral-700">
          {/* Author Info */}
          <div className="relative flex items-center pr-6">
            <div className="absolute -right-3 -top-3">
              <NcDropDown
                className={`p-2 text-neutral-500 flex items-center justify-center rounded-lg hover:text-neutral-800 dark:hover:text-neutral-200 sm:hover:bg-neutral-100 dark:hover:bg-neutral-800 ${twFocusClass()}`}
                data={actions}
                onClick={handleActionClick}
              />
            </div>
            <Link
              className="font-semibold text-neutral-800 dark:text-neutral-100"
              href={"/"}
            >
              {comment.displayName}
            </Link>
            <span className="mx-2">·</span>
            <span className="text-neutral-500 dark:text-neutral-400 text-xs sm:text-sm">
              {new Date(date).toLocaleString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })}
            </span>
          </div>

          {/* Comment Content */}
          <p className="text-neutral-700 mt-2 mb-3 sm:mt-3 sm:mb-4 dark:text-neutral-300">
            {content}
          </p>

          {/* Like and Reply Actions */}
          {isReplying ? (
            renderReplyForm()
          ) : (
            <CommentCardLikeReply
              className={className}
              isLiked={like?.isLiked || false}
              likeCount={like?.count || 0}
              onClickReply={() => setIsReplying(true)}
            />
          )}
        </div>
      </div>

      {/* Modals */}
      <ModalEditComment
        show={isEditing}
        onCloseModalEditComment={() => setIsEditing(false)}
      />
      <ModalReportItem
        show={isReporting}
        onCloseModalReportItem={() => setIsReporting(false)}
      />
      <ModalDeleteComment
        show={isDeleting}
        onCloseModalDeleteComment={() => setIsDeleting(false)}
      />
    </>
  );
};

export default CommentCardBlog;

// Helper Function to Get SVG Icons
const getSvgIcon = (type: string) => {
  switch (type) {
    case "edit":
      return `<svg ...>...</svg>`; // Add actual SVG
    case "reply":
      return `<svg ...>...</svg>`; // Add actual SVG
    case "report":
      return `<svg ...>...</svg>`; // Add actual SVG
    case "delete":
      return `<svg ...>...</svg>`; // Add actual SVG
    default:
      return "";
  }
};
