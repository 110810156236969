import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import Layout from "app/(others)/layout";
import BlogHeader from "./BlogHeader";
import SingleContentBlog from "./SingleContent";
import { getBlogById, addComment } from "services/blogService";
import { PostDataType } from "data/types";
import CommentCard from "components/CommentCard/CommentCard";
import ButtonPrimary from "components/Button/ButtonPrimary";
import SingleCommentForm from "app/(singles)/SingleCommentForm";
import SingleCommentFormBlog from "./SingleCommentFormBlog";
import { CommentType } from "types/types";
import CommentCardBlog from "./CommentCardBlog";
import BlogComments from "./BlogComments";

const PageBlogDetail = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState<PostDataType | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchBlog = async () => {
      if (!id) {
        setError("Invalid blog ID");
        return;
      }
      try {
        const data = await getBlogById(id);
        setBlog(data);
      } catch {
        setError("Failed to fetch the blog.");
      }
    };

    fetchBlog();
  }, [id]);

  if (error) {
    return (
      <div className="container mx-auto text-center text-red-500">{error}</div>
    );
  }

  if (!blog) {
    return <div className="container mx-auto text-center">Loading...</div>;
  }

  return (
    <Layout>
      <Helmet>
        {/* Dynamic Title and Description */}
        <title>{`${blog.title} | Inspired by the Cross`}</title>
        <meta name="description" content={blog.desc} />

        {/* Open Graph Metadata */}
        <meta property="og:title" content={blog.title} />
        <meta property="og:description" content={blog.desc} />
        <meta
          property="og:image"
          content={blog.featuredImage || blog.mobileImage}
        />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="article" />

        {/* Twitter Metadata */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={blog.title} />
        <meta name="twitter:description" content={blog.desc} />
        <meta
          name="twitter:image"
          content={blog.featuredImage || blog.mobileImage}
        />
      </Helmet>

      <div className="pt-8 lg:pt-16">
        {/* Blog Header */}
        <header className="container rounded-xl">
          <div className="max-w-screen-md mx-auto">
            <BlogHeader post={blog} />
          </div>
        </header>

        {/* Blog Content */}
        <main className="container max-w-screen-md mx-auto space-y-10">
          <div className="prose dark:prose-dark">
            <SingleContentBlog post={blog} />
            <img
              src={blog.mobileImage}
              alt={blog.title}
              className="w-full h-full object-cover rounded-lg shadow-lg"
            />
          </div>
          <SingleCommentFormBlog
            postId={blog._id}
            onSubmitComment={function (comment: CommentType): void {
              console.log(comment);
              // Add the comment
              addComment(comment, blog._id);
            }}
          />

          <ul className="nc-SingleCommentLists space-y-5">
            {/* Render comments */}
            <BlogComments comments={(blog.comments ?? []).slice(0, 20)} />

            {/* Show the button only if there are more than 100 comments */}
            {(blog.comments?.length ?? 0) > 20 && (
              <ButtonPrimary
                className="dark:bg-primary-700 w-full"
                onClick={() => console.log("View full comments clicked")} // Replace with your actual handler
              >
                View full comments (+{(blog.comments?.length ?? 0) - 20}{" "}
                comments)
              </ButtonPrimary>
            )}
          </ul>
        </main>
      </div>
    </Layout>
  );
};

export default PageBlogDetail;
