import React, { useEffect, useState } from "react";
import { onAuthStateChanged, User } from "firebase/auth";
import { auth } from "config/firebase";
import Input from "components/Input/Input";
import ButtonPrimary from "components/Button/ButtonPrimary";
import NcLink from "components/NcLink/NcLink";
import Heading2 from "components/Heading/Heading2";
import Layout from "app/(others)/layout";
import LoginComponent from "app/(others)/login/LoginComponent";
import { addPrayerRequest } from "services/blogService";

const AddPrayerRequestPage = () => {
  const [prayerRequest, setPrayerRequest] = useState({
    title: "",
    details: "",
    category: "Prayer Request",
    location: "",
    isAnswered: false,
    isPublic: true,
    isPrayed: false,
    prayerCountByUser: 0,
    userId: "",
    userDisplayName: "",
    groupId: "",
    createdDate: new Date().toISOString(),
  });

  const [user, setUser] = useState<User | null>(null);

  // Monitor authentication state
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        setPrayerRequest((prev) => ({
          ...prev,
          userId: currentUser.uid,
          userDisplayName: currentUser.displayName || "User",
        }));
      } else {
        setUser(null);
        // Clear form and prevent interaction if not logged in
        setPrayerRequest((prev) => ({
          ...prev,
          userId: "",
          userDisplayName: "",
        }));
      }
    });

    return () => unsubscribe();
  }, []);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type } = e.target;
    const checked =
      type === "checkbox" ? (e.target as HTMLInputElement).checked : undefined;

    setPrayerRequest((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      console.log("Submitting Prayer Request: ", prayerRequest);
      const response = await addPrayerRequest(prayerRequest);

      if (response.success) {
        alert("Prayer request submitted successfully!");
        setPrayerRequest((prev) => ({
          ...prev,
          title: "",
          details: "",
          location: "",
          isAnswered: false,
          isPublic: true,
          isPrayed: false,
          prayerCountByUser: 0,
        }));
      } else {
        throw new Error(response.message || "Failed to submit prayer request.");
      }
    } catch (error) {
      console.error("Error submitting prayer request: ", error);
      alert("An error occurred while submitting your prayer request. Please try again.");
    }
  };

  return (
    <Layout>
      <header className="text-center max-w-2xl mx-auto mb-14 sm:mb-16 lg:mb-20">
        <Heading2>Add Prayer Request</Heading2>
        <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
          Share your prayer request with our community.
        </span>
      </header>

      {/* Show login component if user is not logged in */}
      {!user && (
        <div className="text-center">
          <LoginComponent />
          <p className="text-neutral-700 dark:text-neutral-300 mt-4">
            Please log in to submit a prayer request.
          </p>
        </div>
      )}

      {/* Form is disabled if the user is not logged in */}
      {user && (
        <div className="max-w-md mx-auto space-y-6">
          <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
            {/* Title */}
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">Title</span>
              <Input
                name="title"
                type="text"
                placeholder="Enter your prayer title"
                className="mt-1"
                value={prayerRequest.title}
                onChange={handleChange}
                required
              />
            </label>

            {/* Details */}
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">Details</span>
              <textarea
                name="details"
                placeholder="Describe your prayer request in detail"
                className="mt-1 p-3 border rounded-lg w-full"
                value={prayerRequest.details}
                onChange={handleChange}
                rows={4}
                required
              />
            </label>

            {/* Location */}
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Location (optional)
              </span>
              <Input
                name="location"
                type="text"
                placeholder="Enter location"
                className="mt-1"
                value={prayerRequest.location}
                onChange={handleChange}
              />
            </label>

            {/* Public/Private */}
            <label className="block">
              <div className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  name="isPublic"
                  checked={prayerRequest.isPublic}
                  onChange={handleChange}
                  className="h-5 w-5 border-neutral-300 dark:border-neutral-700 rounded"
                />
                <span className="text-neutral-800 dark:text-neutral-200">
                  Make this prayer public
                </span>
              </div>
            </label>

            {/* Submit Button */}
            <ButtonPrimary type="submit">Submit Prayer Request</ButtonPrimary>
          </form>

          {/* Back to Dashboard */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            <NcLink href="/pray-together">Go back to Prayer List</NcLink>
          </span>
        </div>
      )}
    </Layout>
  );
};

export default AddPrayerRequestPage;