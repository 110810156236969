import React, { FC, useState } from "react";

export interface PrayingHandsProps {
  containerClassName?: string;
  prayedFor?: boolean; // Represents "prayed for" state
}

const PrayingHands: FC<PrayingHandsProps> = ({
  containerClassName = "h-10 w-10 bg-neutral-50 hover:bg-neutral-100 dark:bg-neutral-800 dark:hover:bg-neutral-700",
  prayedFor = false,
}) => {
  const [isPrayedFor, setIsPrayedFor] = useState(prayedFor);

  return (
    
    <button
      className={`nc-PrayingHands relative rounded-full flex items-center justify-center ${containerClassName}`}
      title={isPrayedFor ? "Unmark prayer" : "Mark as prayed for"}
      onClick={() => setIsPrayedFor(!isPrayedFor)}
    > 
      {/* Praying Hands SVG */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill={isPrayedFor ? "currentColor" : "none"}
        stroke="currentColor"
        strokeWidth={1.8}
        className="w-[22px] h-[22px]"
      >
        {/* Hands */}
        <path
          d="M12 2c.6.5 1 1.2 1 2v6m-2-8c-.6.5-1 1.2-1 2v6m0 0l-3 4.5M12 10l3 4.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        
        {/* Rays of light */}
        <path
          d="M4 9h2M18 9h2M6 4l1.5 1.5M16.5 5.5L18 4M6 14.5L4 16M18 14.5L20 16"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  );
};

export default PrayingHands;