import React, { FC } from "react";
import { PostDataType } from "data/types";
import NcImage from "components/NcImage/NcImage";
import ButtonSecondary from "components/Button/ButtonSecondary";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import PrayerFeedCard from "./PrayerFeedCard";
import PostCardLikeAndComment from "components/PostCardLikeAndComment/PostCardLikeAndComment";
import PostCardSaveAction from "components/PostCardSaveAction/PostCardSaveAction";
import { PrayerRequest } from "types/types";

export interface SectionHeroPrayerRequestProps {
  prayers:  PrayerRequest[];
  className?: string;
}

const SectionHeroPrayerRequest: FC<SectionHeroPrayerRequestProps> = ({
  prayers,
  className = "",
}) => {
  const renderMain = () => {
    const { title, details, createdDate, _id } = prayers[0];
    return (
      <div className="aspect-h-8 aspect-w-8 sm:aspect-w-10 lg:aspect-w-16 ">
        <NcImage
          alt=""
          containerClassName="absolute inset-0 rounded-[40px] overflow-hidden z-0"
          src="https://inspiredbythecross.nyc3.digitaloceanspaces.com/prayer-header.png"
          fill
          sizes="(max-width: 1024px) 100vw, 1280px"
        />
        <span className="absolute inset-0 rounded-[40px] bg-black bg-opacity-50"></span>
        <div className="absolute inset-0 p-5 md:p-14 xl:p-20 2xl:p-28">
          <div className="max-w-2xl">
            <h2 className="text-xl sm:text-3xl lg:text-4xl font-semibold text-white">
              <span className="line-clamp-2">
                Add Your Voice to Our Prayer Circle
              </span>
            </h2>
            <span className="block text-sm sm:text-base text-neutral-300 mt-3 sm:mt-5">
              <span className="line-clamp-2">
                Join a global community of faith by sharing your prayer request.
                Let others support you in prayer and find solace together.
              </span>
            </span>
            <div className="mt-5 sm:mt-8">
              <ButtonSecondary href="/pray-together/add">
                <span> Add Prayer Request</span>
                <ArrowRightIcon className="w-6 h-6 ml-3" />
              </ButtonSecondary>
            </div>
           
          </div>
        </div>
      </div>
    );
  };

  const renderSubPosts = () => {
    const subPosts = prayers.filter((_, i) => i >= 1 && i < 3);
    return (
      <div className="lg:px-14 xl:px-20 2xl:px-28 grid sm:grid-cols-2 md:grid-cols-2 gap-4 lg:gap-8 transform mt-6 md:-mt-20">
        {subPosts.map((prayers) => (
          <PrayerFeedCard
            className="bg-white dark:bg-neutral-800 shadow-2xl rounded-3xl"
            key={prayers._id}
            post={prayers}
          />
        ))}
      </div>
    );
  };

  return (
    <div className={`nc-SectionHero3 ${className}`}>
      {prayers.length && renderMain()}
      {prayers.length > 1 && renderSubPosts()}
    </div>
  );
};

export default SectionHeroPrayerRequest;
