import NcBookmark from "components/NcBookmark/NcBookmark";
import PrayingHands from "components/PrayingHands/PrayingHands";
import React, { FC } from "react";

export interface PostCardPrayerActionProps {
  className?: string;
  bookmarkClass?: string;
  readingTime?: number;
  hidenReadingTime?: boolean;
}

const PostCardPrayerAction: FC<PostCardPrayerActionProps> = ({
  className = "",
  bookmarkClass,
  hidenReadingTime = true,
  readingTime = 3,
}) => {
  return (
    <div
      className={`nc-PostCardSaveAction flex items-center space-x-2 text-xs text-neutral-700 dark:text-neutral-300 ${className}`}
    >
      {!hidenReadingTime && !!readingTime && (
        <span>{readingTime} min read</span>
      )}

      <PrayingHands containerClassName={bookmarkClass} />
    </div>
  );
};

export default PostCardPrayerAction;
