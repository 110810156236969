import React, { FC } from "react";
import CommentCardBlog from "./CommentCardBlog";
import { CommentType } from "data/types";

const BlogComments: FC<{ comments?: CommentType[] }> = ({ comments = [] }) => {
  return (
    <div className="space-y-4">
      {comments
        .slice() // Create a copy of the array
        //.filter((comment) => comment.isApproved) // Include only approved comments
        .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()) // Sort by date in descending order
        .map((comment) => (
          <CommentCardBlog key={comment._id} comment={comment} />
        ))}
    </div>
  );
};

export default BlogComments;
