import React, { FC, useState } from "react";
import ButtonPrimary from "components/Button/ButtonPrimary";
import Textarea from "components/Textarea/Textarea";
import Button from "components/Button/Button";
import { auth } from "config/firebase";
import LoginComponent from "app/(others)/login/LoginComponent";
import { CommentType } from "types/types";

export interface SingleCommentFormBlogProps {
  className?: string;
  postId: string; // ID of the post for which the comment is being added
  onSubmitComment: (comment: CommentType) => void; // Function to handle comment submission
  onClickCancel?: () => void;
  textareaRef?: React.MutableRefObject<null>;
  defaultValue?: string;
  rows?: number;
}

const SingleCommentFormBlog: FC<SingleCommentFormBlogProps> = ({
  className = "mt-5",
  postId,
  onSubmitComment,
  onClickCancel,
  textareaRef,
  defaultValue = "",
  rows = 4,
}) => {
  const user = auth.currentUser;

  // State for the comment content
  const [content, setContent] = useState(defaultValue || "");

  // Debugging: Log user details
  console.log("Authenticated User:", user);

  if (!user) {
    return (
      <div className="text-center">
        <LoginComponent />
        <p className="text-neutral-700 dark:text-neutral-300 mt-4">
          Please log in to submit a comment.
        </p>
      </div>
    );
  }

  // Extract user details with fallbacks
  const userName = user.displayName || "Anonymous User";
  const userPhoto = user.photoURL || "https://via.placeholder.com/40";

  // Handle form submission
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!content.trim()) {
      alert("Comment cannot be empty.");
      return;
    }

    // Create the comment object
    const newComment: CommentType = {
    _id: Math.random().toString(36).substr(2, 9), // Random ID
      userId: user.uid,
      postId: postId,
      date: new Date().toISOString(),
      content,
      like: { count: 0, isLiked: false },
      reply: [],
      isApproved: false,
      photoURL: user.photoURL || "",
      displayName : user.displayName  || ""
    };

    // Call the parent-provided submission handler
    onSubmitComment(newComment);

    // Reset the textarea
    setContent("");
  };

  return (
    <form
      action="#"
      className={`nc-SingleCommentForm ${className}`}
      onSubmit={handleSubmit}
    >
      <div className="flex items-center mb-4">
        <img
          src={userPhoto}
          alt={`${userName}'s profile`}
          className="w-10 h-10 rounded-full mr-3"
          onError={(e) => (e.currentTarget.src = "https://via.placeholder.com/40")} // Fallback image
        />
        <span className="text-neutral-900 dark:text-neutral-100 font-medium">
          {userName}
        </span>
      </div>
      <Textarea
        placeholder="Add to discussion"
        value={content}
        onChange={(e) => setContent(e.target.value)}
        required
        rows={rows}
      />
      <div className="mt-2 space-x-3">
        <ButtonPrimary type="submit">Submit</ButtonPrimary>
        <Button type="button" pattern="white" onClick={onClickCancel}>
          Cancel
        </Button>
      </div>
    </form>
  );
};

export default SingleCommentFormBlog;