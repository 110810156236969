import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyAs28u4A-QpAumEX3N5uUxnLa5u6QjyCRU",
    authDomain: "inspired-by-the-cross.firebaseapp.com",
    projectId: "inspired-by-the-cross",
    storageBucket: "inspired-by-the-cross.firebasestorage.app",
    messagingSenderId: "494117718294",
    appId: "1:494117718294:web:ed10c18a887c23cf2ba837",
    measurementId: "G-NTS17RW1RL"
};

// Initialize Firebase
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Auth and export it
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();

export default app;