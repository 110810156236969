import React, { useEffect, useState } from "react";
import { onAuthStateChanged, User } from "firebase/auth";
import { auth, googleProvider } from "config/firebase";
import { signInWithPopup } from "firebase/auth";
import Image from "components/Image";
import googleSvg from "images/Google.svg";
import axios from "axios";

const LoginComponent = () => {
    const [user, setUser] = useState<User | null>(null);

    // Monitor authentication state
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                handleUserLogin(currentUser);
            }
            setUser(currentUser);
        });
        return () => unsubscribe();
    }, []);

    const handleGoogleLogin = async () => {
        try {
            const result = await signInWithPopup(auth, googleProvider);
            //alert(`Welcome, ${result.user.displayName || "User"}!`);
            handleUserLogin(result.user); // Save or update the user in the backend
        } catch (error) {
            console.error("Login failed: ", error);
            //alert("Login failed. Please try again.");
        }
    };

    const handleUserLogin = async (firebaseUser: User) => {
        try {
            const userData = {
                authProvider: "Google",
                email: firebaseUser.email || "",
                displayName: firebaseUser.displayName || "Unknown User",
                photoURL: firebaseUser.photoURL || "",
                uid: firebaseUser.uid,
            };
    
            // Check if the user exists
            const existingUserResponse = await axios.get(
                `https://api.inspiredbythecross.com/api/user/${userData.uid}`
            ).catch(err => {
                // Handle 404 separately to create the user
                if (err.response && err.response.status === 404) {
                    return null;
                }
                throw err; // Re-throw other errors
            });
    
            if (!existingUserResponse) {
                // User not found; create a new user
                const createResponse = await axios.post(
                    "https://api.inspiredbythecross.com/api/user",
                    userData
                );
                console.log("User created: ", createResponse.data);
            } else {
                // User exists; update the user
                const updateResponse = await axios.put(
                    `https://api.inspiredbythecross.com/api/user/${userData.uid}`,
                    userData
                );
                console.log("User updated: ", updateResponse.data);
            }
        } catch (error) {
            console.error("Failed to save/update user: ", error);
        }
    };

    return (
        <div className="max-w-md mx-auto space-y-6">
            <div className="grid gap-3">
                <div className="">
                    {user ? (
                        <div className="text-center">
                            <h3 className="text-neutral-700 dark:text-neutral-300">
                                Welcome back, {user.displayName || "User"}!
                            </h3>
                        </div>
                    ) : (
                        <div className="space-y-4">
                            <button
                                onClick={handleGoogleLogin}
                                className="flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
                            >
                                <Image
                                    className="flex-shrink-0"
                                    src={googleSvg}
                                    alt="Google Login"
                                />
                                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                                    Continue with Google
                                </h3>
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default LoginComponent;