import React, { useEffect, useState } from "react";
import SectionHeroPrayerRequest from "./SectionHeroPrayerRequest";
import { getPrayerRequests } from "services/blogService";
import { PrayerRequest, PrayerRequestsResponse } from "types/types";
import PrayerFeedCard from "./PrayerFeedCard";
import Heading2 from "components/Heading/Heading2";
const PrayTogether = () => {
  const [prayerRequests, setPrayerRequests] = useState<PrayerRequest[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  // Fetch Prayer Requests
  useEffect(() => {
    const fetchPrayerRequests = async () => {
      try {
        const data = await getPrayerRequests();
        setPrayerRequests(data.prayerRequests); // Assuming response has `prayerRequests`
      } catch (err) {
        setError("Failed to fetch prayer requests. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchPrayerRequests();
  }, []);

  if (loading) {
    return (
      <div className="container mx-auto text-center">
        <p>Loading prayer requests...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mx-auto text-center text-red-500">
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className="nc-PageHomeDemo2 relative">
      <div className="container relative">
        {/* Hero Section for Prayer Requests */}
        <SectionHeroPrayerRequest
          className="pb-16 lg:pb-28"
          prayers={prayerRequests} // Pass dynamic prayer requests to the component
        />

      <header className="text-center max-w-2xl mx-auto mb-14 sm:mb-16 lg:mb-20">
        <Heading2>Add Prayer Request</Heading2>
        <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
          Share your prayer request with our community.
        </span>
      </header>
      <br />

        <div className="lg:px-14 xl:px-20 2xl:px-28 grid sm:grid-cols-2 md:grid-cols-2 gap-4 lg:gap-8 transform mt-6 md:-mt-20">
          {prayerRequests.map((prayers) => (
            <PrayerFeedCard
              className="bg-white dark:bg-neutral-800 shadow-2xl rounded-3xl"
              key={prayers._id}
              post={prayers}
            />
          ))}
        </div>
        {/* Additional sections if needed */}
      </div>
      <br />
    </div>
  );
};

export default PrayTogether;